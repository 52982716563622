<template>
  <app-collapse class="mb-2">
    <app-collapse-item
      title
      is-visible
    >
      <template #header>
        <div
          class="w-100 d-flex justify-content-between align-items-center pl-1"
        >
          <div
            class="d-flex align-items-center"
          >
            <template
              v-if="!isNameEditing"
            >
              <h4
                v-if="option.name"
                class="mb-0 mr-2"
              >
                {{ option.name }}
              </h4>
              <h4
                v-else
                class="mb-0 mr-2"
              >
                Option {{ index + 1 }}
              </h4>
              <feather-icon
                icon="EditIcon"
                size="24"
                class="cursor-pointer text-primary mr-2"
                @click.stop="editOptionName"
              />
            </template>
            <template v-else>
              <b-form-input
                v-model="option.name"
                v-click-outside="applyOptionName"
                style="width: 250px"
                @click.stop
              />
              <feather-icon
                icon="CheckIcon"
                size="24"
                class="cursor-pointer text-primary mr-2"
                @click.stop="applyOptionName"
              />
            </template>
            <span class="text-primary">{{ optionDescription() }}</span>
          </div>

          <div
            v-if="canUpdatePaymentContract"
            @click.stop="deleteItem"
          >
            <SquareIcon
              size="21"
              icon="Trash2Icon"
              class="cursor-pointer"
            />
          </div>
        </div>
      </template>

      <hr class="m-0">

      <validation-observer
        ref="paymentContractOption"
      >
        <b-row class="mt-1 p-1">
          <b-col
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Price Options"
              rules="required"
            >
              <b-form-group
                label="Price Options"
              >
                <v-select
                  v-model="option.price_option.priceType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="priceTypeOptions"
                  :clearable="false"
                  placeholder="Select"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            v-if="option.price_option.priceType == 'Percent discount'"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Amount"
              rules="required"
            >
              <b-form-group
                label="Amount Of Percent Discount (%)"
              >
                <b-form-input
                  v-model="option.price_option.percentDiscount"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            v-if="option.price_option.priceType == 'Dollar discount'"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Amount"
              rules="required"
            >
              <b-form-group
                label="Amount Of Dollar Discount ($)"
              >
                <b-form-input
                  v-model="option.price_option.dollarDiscount"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <hr class="m-0">

        <div class="mt-1 p-1">
          <b-row>
            <b-col
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Time Schedule Options"
                rules="required"
              >
                <b-form-group
                  label="Time Schedule Options"
                >
                  <v-select
                    v-model="option.schedule_option.timeSchedule"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="timeScheduleOptions"
                    :clearable="false"
                    placeholder="Select"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              v-if="option.schedule_option.timeSchedule == 'Split over'"
              md="4"
            >
              <b-form-group
                label="Payment Amount Range"
              >
                <label
                  for="range-from"
                  class="mr-1"
                >
                  From
                </label>
                <b-form-spinbutton
                  id="range-from"
                  v-model="option.schedule_option.paymentAmountRange.from"
                  min="1"
                  inline
                  class="mr-1"
                  @input="updateAmountRangeTo"
                />
                <label
                  for="range-to"
                  class="mr-1"
                >
                  To
                </label>
                <b-form-spinbutton
                  id="range-to"
                  v-model="option.schedule_option.paymentAmountRange.to"
                  :min="option.schedule_option.paymentAmountRange.from"
                  max="30"
                  inline
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="option.schedule_option.timeSchedule == 'Split over'"
              md="4"
            >
              <b-form-group
                label="Required Deposit ($)"
              >
                <b-form-input
                  v-model="option.price_option.requiredDeposit"
                  placeholder="0"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="option.schedule_option.timeSchedule == 'Split over'"
              sm="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Pay Date"
                rules="required"
              >
                <b-form-group
                  label="Pay Date(ex: 1, 15, 1-15)"
                >
                  <b-form-input
                    v-model="option.schedule_option.payDate"
                    placeholder="Pay Date"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              v-if="option.schedule_option.timeSchedule == 'Split over'"
              sm="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                rules="required"
              >
                <b-form-group
                  label="Start Date"
                >
                  <flat-pickr
                    v-model="option.schedule_option.startDate"
                    placeholder="Select date"
                    class="form-control"
                    :config="{
                      dateFormat: 'm/d/Y'
                    }"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              v-if="option.schedule_option.timeSchedule == 'Split over'"
              sm="4"
            >
              <validation-provider
                #default="{ errors }"
                name="End Date"
                rules="required"
              >
                <b-form-group
                  label="End Date"
                >
                  <flat-pickr
                    v-model="option.schedule_option.endDate"
                    placeholder="Select date"
                    class="form-control"
                    :config="{
                      enable: [enableEndDates],
                      dateFormat: 'm/d/Y'
                    }"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

          </b-row>
        </div>

        <hr class="m-0">

        <b-row class="mt-1 p-1">
          <b-col
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Payment Method"
              rules="required"
            >
              <b-form-group
                label="Payment Method"
              >
                <v-select
                  v-model="option.method"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethodOptions"
                  multiple
                  :close-on-select="false"
                  placeholder="Select"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>

      <hr class="m-0">

      <b-row
        v-if="paymentContractId"
      >
        <b-col
          cols="12"
        >
          <div class="d-flex my-2 justify-content-end align-items-center">
            <div>
              <b-button
                class="mr-2"
                variant="primary"
                :disabled="!canUpdatePaymentContract"
                @click="saveOption"
              >
                Save Option
              </b-button>

              <b-button
                variant="outline-primary"
                @click="$emit('updatePaymentContractData')"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import Vue from 'vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BButton,
} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import vClickOutside from 'v-click-outside'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

import usePaymentContractOption from '@/views/admin/payment-contracts/payment-contract/usePaymentContractOption'
import { setFormatDate } from '@/helpers/dateFormater'

Vue.use(vClickOutside)

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSpinbutton,
    BButton,

    ValidationObserver,
    ValidationProvider,

    SquareIcon,
    vSelect,
    flatPickr,
  },
  props: {
    paymentContractId: {
      type: [String, Number],
      default: '',
    },
    option: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    canUpdatePaymentContract: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      priceTypeOptions: [
        'Full Price', 'Percent discount', 'Dollar discount',
      ],
      timeScheduleOptions: [
        'Charge Immediately', 'Split over',
      ],
      paymentMethodOptions: [],
      isNameEditing: false,
      required,
    }
  },
  setup() {
    const {
      createOption,
      updateOption,
      deleteOption,
      fetchPaymentMethods,
    } = usePaymentContractOption()

    return {
      createOption,
      updateOption,
      deleteOption,
      fetchPaymentMethods,
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'option.schedule_option.startDate'() {
      this.resetSelectedEndDate()
      this.enableEndDates()
    },
    // eslint-disable-next-line object-shorthand
    'option.price_option.priceType'() {
      this.resetPriceOptionDiscounts()
    },
  },
  async created() {
    await this.setPaymentMethodsList()
  },
  methods: {
    async setPaymentMethodsList() {
      const response = await this.fetchPaymentMethods()
      this.paymentMethodOptions = response.reduce((arr, item) => {
        if (!arr.includes(item.name)) {
          arr.push(item.name)
        }
        return arr
      }, [])
    },
    optionDescription() {
      let string = ''

      if (this.option.price_option.priceType) {
        string += `${this.option.price_option.priceType} - `
        if (this.option.price_option.priceType === 'Percent discount') {
          string += ` ${this.option.price_option.percentDiscount}% - `
        }
        if (this.option.price_option.priceType === 'Dollar discount') {
          string += ` $${this.option.price_option.dollarDiscount} - `
        }
      }

      if (this.option.schedule_option.timeSchedule) {
        string += `${this.option.schedule_option.timeSchedule} - `
        if (this.option.schedule_option.timeSchedule === 'Split over') {
          string += ` ${this.option.schedule_option.paymentAmountRange.from}-${this.option.schedule_option.paymentAmountRange.to} - `
          if (this.option.schedule_option.payDate) string += `Pay Date ${this.option.schedule_option.payDate} - `
          if (this.option.schedule_option.startDate) string += `Start Date ${this.option.schedule_option.startDate} - `
          if (this.option.schedule_option.endDate) string += `End Date ${this.option.schedule_option.endDate} - `
        }
      }
      if (this.option.method) {
        string += this.option.method
      }

      return string
    },
    updateAmountRangeTo(val) {
      if (val >= this.option.schedule_option.paymentAmountRange.to) this.option.schedule_option.paymentAmountRange.to = val
    },
    async deleteItem() {
      if (this.option.id) {
        await this.deleteOption(this.option.id).then(() => {
          this.$emit('deleteOption', this.index)
        })
      } else {
        this.$emit('deleteOption', this.index)
      }
    },
    enableEndDates(date) {
      const { startDate } = this.option.schedule_option
      return date > new Date(setFormatDate(startDate))
    },
    resetSelectedEndDate() {
      this.option.schedule_option.endDate = null
    },
    resetPriceOptionDiscounts() {
      this.option.price_option.percentDiscount = 0
      this.option.price_option.dollarDiscount = 0
    },
    async saveOption() {
      return new Promise((resolve, reject) => {
        this.$refs.paymentContractOption.validate().then(success => {
          if (success) {
            if (this.option.id) {
              const queryParams = { ...this.option }
              queryParams.schedule_option.startDate = setFormatDate(this.option.schedule_option.startDate)
              queryParams.schedule_option.endDate = setFormatDate(this.option.schedule_option.endDate)

              this.updateOption(queryParams).then(() => {
                this.$emit('updatePaymentContractData')
              })
            } else {
              const queryParams = { ...this.option }
              queryParams.schedule_option.startDate = setFormatDate(this.option.schedule_option.startDate)
              queryParams.schedule_option.endDate = setFormatDate(this.option.schedule_option.endDate)

              this.createOption(this.option).then(() => {
                this.$emit('updatePaymentContractData')
              })
            }
          } else {
            reject()
          }
        })
      })
    },
    editOptionName() {
      this.isNameEditing = true
    },
    applyOptionName() {
      this.isNameEditing = false
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
