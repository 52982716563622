<template>
  <div>
    <h2>Payment Contract</h2>
    <b-spinner
      v-if="isLoading"
      class="align-middle"
    />
    <div v-else>
      <validation-observer
        ref="paymentContract"
      >
        <b-card>
          <b-col
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              :rules="{required: true, isUnique: paymentContractId ? false : [allPaymentContracts]}"
            >
              <b-form-group
                label="Payment Contract Name"
              >
                <b-form-input
                  v-model="paymentContractName"
                  placeholder="Name"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-card>
      </validation-observer>

      <payment-contract-option
        v-for="(option,index) in paymentContractOptions"
        :key="index"
        :payment-contract-id="paymentContractId"
        :option="option"
        :index="index"
        :can-update-payment-contract="canUpdatePaymentContract"
        @deleteOption="deleteOption"
        @updatePaymentContractData="setPaymentContractData"
      />

      <b-card
        class="py-2"
      >
        <div class="d-flex justify-content-between align-items-center">
          <b-button
            class="px-5"
            variant="outline-primary"
            :disabled="!canUpdatePaymentContract"
            @click="addNewOption"
          >
            Add Option +
          </b-button>

          <div>
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="!canUpdatePaymentContract"
              @click="saveChanges"
            >
              Save Changes
            </b-button>

            <b-button
              variant="outline-primary"
              @click="cancel"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, isUnique } from '@validations'

import PaymentContractOption from '@/views/admin/payment-contracts/payment-contract/PaymentContractOption.vue'
import usePaymentContractsSetting from '@/views/admin/payment-contracts/usePaymentContractsSetting'
import { getFormatDate, setFormatDate } from '@/helpers/dateFormater'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,

    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,

    PaymentContractOption,
  },
  data() {
    return {
      isLoading: false,
      paymentContractId: this.$router.currentRoute.query?.id,
      paymentContractName: '',
      paymentContractOptions: [],
      allPaymentContracts: [],
      required,
      isUnique,
    }
  },
  computed: {
    canUpdatePaymentContract() {
      if (this.paymentContractId) {
        return this.$can('update', permissionSubjects.PaymentContract)
      }
      return true
    },
    preparedPaymentOptions() {
      const options = JSON.parse(JSON.stringify(this.paymentContractOptions))

      options.forEach(option => {
        // eslint-disable-next-line no-param-reassign
        option.schedule_option.startDate = option.schedule_option.startDate ? setFormatDate(option.schedule_option.startDate) : null
        // eslint-disable-next-line no-param-reassign
        option.schedule_option.endDate = option.schedule_option.endDate ? setFormatDate(option.schedule_option.endDate) : null
      })

      return options
    },
  },
  setup() {
    const {
      createPaymentContract,
      updatePaymentContract,
      fetchPaymentContract,
      fetchPaymentContractList,
    } = usePaymentContractsSetting()

    return {
      createPaymentContract,
      updatePaymentContract,
      fetchPaymentContract,
      fetchPaymentContractList,
    }
  },
  created() {
    this.setPaymentContractData()
  },
  methods: {
    async setPaymentContractData() {
      if (this.paymentContractId) {
        this.isLoading = true
        await this.fetchPaymentContract(this.paymentContractId)
          .then(response => {
            this.paymentContractName = response.name
            this.paymentContractOptions = response.options.filter(item => item.user_id === null)
            this.paymentContractOptions.forEach(option => {
              // eslint-disable-next-line no-param-reassign
              option.schedule_option.startDate = getFormatDate(option.schedule_option.startDate)
              // eslint-disable-next-line no-param-reassign
              option.schedule_option.endDate = getFormatDate(option.schedule_option.endDate)
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.paymentContractOptions = [
          {
            name: null,
            price_option: {
              priceType: '',
              percentDiscount: 0,
              dollarDiscount: 0,
              requiredDeposit: 0,
            },
            schedule_option: {
              timeSchedule: '',
              paymentAmountRange: {
                from: 1,
                to: 1,
              },
              payDate: null,
              endDate: null,
              startDate: null,
            },
            method: [],
          },
        ]
      }

      this.allPaymentContracts = await this.fetchPaymentContractList()
    },
    addNewOption() {
      const newOption = {
        name: null,
        price_option: {
          priceType: '',
          percentDiscount: 0,
          dollarDiscount: 0,
          requiredDeposit: 0,
        },
        schedule_option: {
          timeSchedule: '',
          paymentAmountRange: {
            from: 2,
            to: 3,
          },
          payDate: null,
          endDate: null,
          startDate: null,
        },
        method: [],
      }
      if (this.paymentContractId) {
        newOption.payment_contract_id = this.paymentContractId
      }

      this.paymentContractOptions.push(newOption)
    },
    deleteOption(index) {
      this.paymentContractOptions.splice(index, 1)
    },
    saveChanges() {
      // Validate all options
      const validationArray = this.$children
        .filter(child => child.$refs.paymentContractOption)
        .map(child => (new Promise((resolve, reject) => {
          child.$refs.paymentContractOption.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
        ))

      validationArray.push(new Promise((resolve, reject) => {
        this.$refs.paymentContract.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      }))

      window.Promise.all(validationArray)
        .then(success => {
          // eslint-disable-next-line no-empty
          if (success) {
            if (this.paymentContractId) {
              this.updateContract()
            } else {
              this.createContract()
            }
          }
        }).catch(() => {
        })
    },
    async createContract() {
      const queryParams = {
        name: this.paymentContractName,
        options: this.preparedPaymentOptions,
      }
      await this.createPaymentContract(queryParams).then(() => {
        this.$router.push({ name: 'admin-payment-contracts' })
      })
    },
    async updateContract() {
      const queryParams = {
        id: this.paymentContractId,
        name: this.paymentContractName,
        options: this.preparedPaymentOptions,
      }
      await this.updatePaymentContract(queryParams).then(() => {
        this.$router.push({ name: 'admin-payment-contracts' })
      })
    },
    cancel() {

    },
  },
}
</script>

<style lang="scss" scoped>
  .overflow {
    overflow-x: scroll;
  }
  .table-header {

    label {
      margin-bottom: 0;
    }
  }
  .per-page-selector {
    width: 90px;
  }
  .break-word{
    word-break: break-all;
  }
  .b-table-sticky-header {
    max-height: 70vh;
}
</style>
